$color-primary-black: #2C2E35;
$color-primary-black-light: #2f2f2f;
$color-primary-white: #FFFEFC;
$color-primary-grey-light: #fafafa;
$color-primary-purple: #c8167c;
$color-primary-red: #e72568;
$color-secondary-red: #f42d72;

$color-semi-transparent: #ffffff80;
$color-black: #2C2E35;
$color-black-secondary: #4B4F5B;
$color-white: #FFF;
$color-offWhite: #fcfcfc;
$color-white-secondary: rgb(245, 245, 245);
$color-primary: #e72568;
$color-secondary: #fbc87c;
$color-accent: #c8167c;

$color-grey25: hsl(0, 0%, 25%);
$color-grey50: hsl(0, 0%, 50%);
$color-grey65: hsl(0, 0%, 65%);
$color-grey75: hsl(0, 0%, 75%);
$color-grey85: hsl(0, 0%, 85%);
$color-grey95: hsl(0, 0%, 95%);

$color-status-success: #9BE87E;
$color-status-notice: #01579b;
$color-status-disabled: #555555;
$color-status-warning: #ff6f00;
$color-status-error: #f01c1c;


$box-shadow-1: 0px 0px 0px 2px rgba($color-white-secondary, 1);
$box-shadow-2: 0px 0px 0px 3px rgba($color-white-secondary, 1);

$color-status-list: (
  success: $color-status-success,
  notice: $color-status-notice,
  disabled: $color-status-disabled, 
  warning: $color-status-warning,
  error: $color-status-error
);
