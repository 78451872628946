
      @use "sass:math";
      @import '~backline-mixins/src/backline-mixins';
      @import 'settings';
    
@import '~backline-normalize/src/backline-normalize';

body,
html {
  height: 100%;
}

*::-webkit-scrollbar {
  background-color: #fff;
  width: 16px;
}


*::-webkit-scrollbar-track {
  background-color: #fff;
}

*::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 4px solid #fff;
}

#__next {
  min-height: 100%;  // flex-flow: column nowrap;
  display: flex;
  flex-direction: column;
}

body {
  font-size: spacing(2.25);
  line-height: spacing(3.25);
  font-weight: normal;
  font-family: $font-body;
  overflow-y: scroll;
  overflow-x: hidden;
}

a {
  color: inherit;
  text-decoration: underline;

  &:focus,
  &:hover {
    color: inherit;
    outline: 0;
  }

  &:active {
    color: inherit;
  }
}

img {
  border-radius: $radius-small;
}

.page-transition-enter,
.page-transition-enter-active,
.page-transition-exit,
.page-transition-exit-active,
.page-transition-enter-done {
  min-height: 100%;
  display: flex;
  flex-flow: column nowrap;
}

.page-transition-enter {
  opacity: 0;
}
.page-transition-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}
.page-transition-exit {
  opacity: 1;
}
.page-transition-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}


::-moz-selection { /* Code for Firefox */
  color: $color-white;
  background: $color-accent;
}

::selection {
  color: $color-white;
  background: $color-accent;
}